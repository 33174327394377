import React from 'react';

const HeroSection = () => {
  return (
	  <div className="container space-top-3 space-top-lg-5 space-bottom-3 space-bottom-lg-3">
		  <div className="row justify-content-lg-between mb-7">
			  <div className="col-md-6 col-lg-5">
				  <div className="mb-5">
					  <h1>Turn your ideas into a success story</h1>
					  <p>Our experience is built around developing custom solutions and offering IT outsourcing services
						  across multiple technologies.</p>
				  </div>

				  <div className="mb-3">
					  <a className="btn btn-primary btn-wide transition-3d-hover mb-2 mb-sm-0 mr-3" href="#contact">Let's talk</a>
				  </div>

				  <p className="small">Whatever your goal - we will help you reach it.</p>
			  </div>

			  <div className="col-md-6 d-none d-md-inline-block">

				  <figure className="w-100">
					  <img
						  className="img-fluid"
						  src="/assets/theme/svg/illustrations/maintenance-mode.svg"
						  alt="Image Description"
					  />
				  </figure>

			  </div>
		  </div>
	  </div>
  );
};

export default HeroSection;
