import React from 'react';
import {Route} from "react-router-dom";

const ContextAwareRoute = ({component: Component, layout: Layout, ...rest}) => {

	const WantedRoute = () => <Route {...rest} render={matchProps => (
		<Layout component={Component} {...matchProps} />
	)}/>

	return (
		<Route
			{...rest}
			render={rProps => <WantedRoute rProps={rProps} />}
		/>
	);
};

export default ContextAwareRoute;
