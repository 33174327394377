const basePath = '/assets/tech-logos';

export const technologies = [

	{
		name: 'Frontend',
		logos: [
			{path: `${basePath}/frontend/js.png`, altText: 'JavaScript'},
			{path: `${basePath}/frontend/html.png`, altText: 'HTML'},
			{path: `${basePath}/frontend/css.png`, altText: 'CSS'},
			{path: `${basePath}/frontend/react.png`, altText: 'ReactJs'},
			{path: `${basePath}/frontend/vue.png`, altText: 'Vue.js'},
			// {path: `${basePath}/frontend/jquery.png`, altText: 'jQuery'},
			{path: `${basePath}/frontend/bootstrap.png`, altText: 'Bootstrap'},
		],
	},
	{
		name: 'Backend',
		logos: [
			{path: `${basePath}/backend/php.png`, altText: 'php, php7'},
			{path: `${basePath}/backend/codeigniter.png`, altText: 'CodeIgniter'},
			{path: `${basePath}/backend/laravel.png`, altText: 'Laravel'},
			{path: `${basePath}/backend/symfony.png`, altText: 'Symfony'},
			{path: `${basePath}/backend/node.png`, altText: 'NodeJS'},
			{path: `${basePath}/backend/java.png`, altText: 'Java'},
		],
	},

	{
		name: 'Persistance and Storage',
		logos: [
			{path: `${basePath}/database/mysql.png`, altText: 'MySQL'},
			{path: `${basePath}/database/postgresql.png`, altText: 'PostgreSQL'},
			{path: `${basePath}/database/mongodb.png`, altText: 'MongoDB'},
			{path: `${basePath}/database/redis.png`, altText: 'Redis'},
			{path: `${basePath}/database/influxdb.png`, altText: 'InfluxDB'},
		],
	},

	{
		name: 'Mobile',
		logos: [
			{path: `${basePath}/mobile/ios.png`, altText: 'iOS Development'},
			{path: `${basePath}/mobile/android.png`, altText: 'Android Development'},
			{path: `${basePath}/mobile/react-native.png`, altText: 'React-Native'},
		],
	},

	{
		name: 'Infrastructure & DevOps',
		logos: [
			{path: `${basePath}/devops/docker.png`, altText: 'Docker'},
			{path: `${basePath}/devops/vagrant.png`, altText: 'Vagrant'},
			{path: `${basePath}/devops/aws.png`, altText: 'Amazon Web Services (AWS)'},
			{path: `${basePath}/devops/google-cloud.png`, altText: 'Google Cloud'},
		],
	},
];
