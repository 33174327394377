import React from 'react';
import InlineSVG from 'svg-inline-react';

import {svgSourceBottom, svgSourceTop} from "./assets";
import {services} from "./services";

import ServiceItem from "./components/ServiceItem";
import ServiceItemContentWrapper from "./components/ServiceItemContent";

const ServicesSection = () => {
	const activePillIndex = 0;

	return (
		<React.Fragment>
			<figure>
				<InlineSVG src={svgSourceTop}/>
			</figure>

			<a name="services" id={'services'} />

			<div className="bg-light">
				<div className="container space-2 space-lg-3">
					<div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
						<h3 className="d-block font-weight-bold text-cap mb-2">Our services</h3>
						<span>We ensure a successful trajectory of your project, from the initial idea until the implementation and beyond, through continuous support and maintenance.</span>
					</div>

					<div className="row">
						<div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
							<ul className="nav nav-box" role="tablist">
								{services.map(
									(item, index) =>
										<ServiceItem
											isActive={activePillIndex === index}
											index={index}
											key={'service-item-' + index}
											{...item}
										/>
								)}
							</ul>
						</div>

						<div className="col-lg-7 order-lg-1 align-self-lg-end">
							<div className="tab-content pr-lg-4">
								{services.map(
									(item, index) =>
										<ServiceItemContentWrapper key={index} index={index} isActive={activePillIndex === index}>
											{item.content}
										</ServiceItemContentWrapper>
								)}
							</div>

						</div>
					</div>
				</div>
			</div>

			<figure>
				<InlineSVG src={svgSourceBottom}/>
			</figure>
		</React.Fragment>
	);
};

export default ServicesSection;
