import React from 'react';

const FormMessage = ({isError}) => {
  return (
    <div>
	    <div className="card-header bg-light text-center py-4 px-5 px-md-6">
		    <h2 className="h4 mb-0">
			    Contact Us
		    </h2>
	    </div>

	    <div className="card p-10 align-content-center align-items-center justify-content-center text-center">
		    {isError === false && (
		    	<React.Fragment>
				    <span>We received your message and will get back to you soon.</span>
			    </React.Fragment>
		    )}

		    {isError === true && (<React.Fragment>
				    <span>There was an error sending your message, please send us your message at <a href="mailto:contact@devaccent.com">contact@devaccent.com</a></span>
		    </React.Fragment>
		    )}
	    </div>

    </div>
  );
};

export default FormMessage;
