import React from 'react';
import Logo from "../../../../../components/Branding/Logo";

const Header = () => {
  return (
    <header id="header" className="header header-bg-transparent header-abs-top py-3">
      <div className="header-section">
        <div id="logoAndNav" className="container">
          <nav className="navbar">
            <Logo />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
