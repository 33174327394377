import React from "react";

const LogosList = ({logos}) => {
	const LogoItem = ({logo}) => (
		<div className="col-4 col-lg-2 mb-4 mb-lg-0">
			<div className="mx-2">
			<img className="max-w-11rem max-w-md-13rem mx-auto"
			     src={logo.path} alt={logo.altText} />
			</div>
		</div>
	);

	return (
		<React.Fragment>
			{logos.map((item, index) => <LogoItem key={index} logo={item} />)}
		</React.Fragment>
	);

};

export default LogosList;
