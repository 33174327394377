import React from 'react';
import Logo from "../../../../../components/Branding/Logo";

const Header = () => {
	const menuLinkClickHandler = (elementId) => (e) => {
		e.preventDefault();
		const element = document.getElementById(elementId);
		const y = element.getBoundingClientRect().top + window.scrollY - 80;
		window.scroll({top: y, behavior: 'smooth'});
	};

  return (
      <header id="header" className="header header-box-shadow-on-scroll header-floating-lg">
        <link rel="stylesheet" href="/assets/theme/vendor/font-awesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/theme/vendor/hs-mega-menu/dist/hs-mega-menu.min.css" />

        <div className="header-section">
          <div id="logoAndNav" className="container">
            <nav className="js-mega-menu navbar navbar-expand-lg">
              <Logo />

              <div id="navBar" className="collapse navbar-collapse">
                <div className="navbar-body header-abs-top-inner">
                  <ul className="navbar-nav">
                    <li className="hs-has-sub-menu navbar-nav-item">
                      <a
                        id="pagesMegaMenu"
                        className="hs-mega-menu-invoker nav-link "
                        href="/#about"
                        aria-labelledby="pagesSubMenu"
                        onClick={menuLinkClickHandler('about')}
                      >About</a>
                    </li>

	                  <li className="hs-has-sub-menu navbar-nav-item">
		                  <a
			                  id="pagesMegaMenu"
			                  className="hs-mega-menu-invoker nav-link "
			                  href="/#services"
			                  onClick={menuLinkClickHandler('services')}
			                  aria-labelledby="pagesSubMenu"
		                  >Services</a>
	                  </li>

	                  <li className="hs-has-sub-menu navbar-nav-item">
		                  <a
			                  id="pagesMegaMenu"
			                  className="hs-mega-menu-invoker nav-link "
			                  href="/#technologies"
			                  onClick={menuLinkClickHandler('technologies')}
			                  aria-labelledby="pagesSubMenu"
		                  >Technologies</a>
	                  </li>

                    <li className="hs-has-sub-menu navbar-nav-item">
                      <a
                        id="pagesMegaMenu"
                        className="hs-mega-menu-invoker nav-link "
                        href="/#company-values"
                        onClick={menuLinkClickHandler('company-values')}
                        aria-labelledby="pagesSubMenu"
                      >Values</a>
                    </li>

                    <li className="hs-has-sub-menu navbar-nav-item">
                      <a
                        id="pagesMegaMenu"
                        className="hs-mega-menu-invoker nav-link "
                        href="/#contact"
                        onClick={menuLinkClickHandler('contact')}
                        aria-labelledby="pagesSubMenu"
                      >Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
  );
};

export default Header;
