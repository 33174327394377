import React from "react";
import FeatureItem from "../components/FeatureItem";
import ServiceDescription from "../components/ServiceDescription";

const DevOpsServicesContent = () => {

	const features = [
		'Cloud Architecture',
		'Serverless architecture',
		'Optimization & scaling',
		'Replication & sharding',
		'Build configuration & automation',
		'Continuous integration & deployment',
		'Monitoring & back-up',
	];

	return (
		<div className="card overflow-hidden">
			<div className="container p-5 p-lg-1 space-lg-2">
				<div className="mx-md-auto">
					<div className="row justify-content-center align-items-md-center">
						<div className="col-md-5 mb-7 mb-md-0">
							{features.map(
								(item, index) =>
									<FeatureItem key={index} text={item} />
							)}
						</div>

						<div className="col-md-7 col-lg-5">
							<ServiceDescription
								description={
									'Take advantage of a large variety of services, from process and pipeline automation, infrastructure-as-code, CI/CD to system administration and infrastructure operations.'
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DevOpsServicesContent;
