import React from 'react';

const FooterLinks = () => {
	return (
		<ul className="list-inline mb-0">
			<li className="list-inline-item">
				<a className="btn btn-xs btn-icon btn-ghost-secondary" href="https://www.facebook.com/devtone.accent">
					<i className="fab fa-facebook-f"></i>
				</a>
			</li>
			<li className="list-inline-item">
				<a className="btn btn-xs btn-icon btn-ghost-secondary" href="">
					<i className="fab fa-google"></i>
				</a>
			</li>
			<li className="list-inline-item">
				<a className="btn btn-xs btn-icon btn-ghost-secondary" href="https://www.linkedin.com/company/devaccent">
					<i className="fab fa-linkedin"></i>
				</a>
			</li>
			<li className="list-inline-item">
				<a className="btn btn-xs btn-icon btn-ghost-secondary" href="https://github.com/devaccent">
					<i className="fab fa-github"></i>
				</a>
			</li>
		</ul>
	);
};

export default FooterLinks;
