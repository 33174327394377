import React from 'react';

const Logo = () => {

  return (
	  <a className="navbar-brand" href="/" aria-label="DevAccent">
		  <img src="/assets/theme/svg/logos/logo-full.svg" alt="DevAccent Logo" />
	  </a>
  );
};

export default Logo;
