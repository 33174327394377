export const svgSourceTop = `
    <svg 
    	preserveAspectRatio="none" 
    	xmlns="http://www.w3.org/2000/svg" 
    	xmlns:xlink="http://www.w3.org/1999/xlink" 
    	x="0px" y="0px" width="100%" height="64px"
      viewBox="0 0 1921 273" 
      style="margin-bottom: -8px; 
      enable-background:new 0 0 1921 273;" 
      xml:space="preserve"
    >
    	<polygon fill="#f9fbff" points="0,273 1921,273 1921,0 "/>
  	</svg>
`;

export const svgSourceBottom = `
    <svg 
    	preserveAspectRatio="none" 
    	xmlns="http://www.w3.org/2000/svg" 
    	xmlns:xlink="http://www.w3.org/1999/xlink" 
    	x="0px" y="0px" width="100%" height="64px"
     	viewBox="0 0 1921 273" 
     	style="margin-bottom: -8px; 
     	enable-background:new 0 0 1921 273;" 
     	xml:space="preserve"
    >
    	<polygon fill="#f9fbff" points="1921,0 0,0 0,273 "/>
  	</svg>
`;
