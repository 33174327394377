import React from 'react';

const values = [
	{
		title: 'Envision big, start small',
		description: 'Everyone is included in our project to reflect individually on what is important to them.'
	},

	{
		title: 'Ambition by the boatload',
		description: 'We love people who aim for greatness. They inspire and excite their teammates, raising the bar for all of us.'
	},

	{
		title: 'Open communication',
		description: 'We\'re big fans of transparency for many reasons, but the abridged version is: it makes easier.'
	},

	{
		title: 'Autonomy and attitude',
		description: 'We\'re a team of self-starters who take serious pride in our work – and it shows.'
	},

	{
		title: 'Support and win',
		description: 'When we empower others to succeed, we all win. (And we\'re not talking about participation trophies.)'
	},

	{
		title: 'Teamwork makes the dream work',
		description: 'We work together to bring our passions and expertise to make Teachable the best it can be.'
	},
];

const CompanyValuesSection = () => {
	const CompanyValue = ({title, description}) => (
		<div className="col-sm-6 col-md-4 mb-3 mb-sm-7">
			<div className="media">
				<span className="mr-3">—</span>
				<div className="media-body">
					<h2 className="h4">{title}</h2>
					<p>{description}</p>
				</div>
			</div>
		</div>
	);

  return (
	  <div className="container space-2">
		  <a name="company-values" id={'company-values'} />
		  <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
			  <h3 className="d-block font-weight-bold text-cap mb-2">Company values</h3>
		  </div>

		  <div className="row">
			  {values.map(
			  	(item, index) =>
					  <CompanyValue
						  key={index}
						  title={item.title}
						  description={item.description}
					  />
		    )}
		  </div>
	  </div>
  );
};

export default CompanyValuesSection;
