import React from "react";

const TechnologyItem = ({children, category}) => {

	return (
		<div className="container mb-9">
			<div className="w-md-80 w-lg-60 text-center mx-md-auto mb-6">
				<h2 className="divider divider-text">{category}</h2>
			</div>

			<div className="row justify-content-sm-center text-center">
				{children}
			</div>
		</div>
	);
};

export default TechnologyItem;
