import React from 'react';

const WhatWeDoSection = () => {
	return (
		<div className="container space-2">
			<a name="about" id={'about'} />
			<div className="w-lg-65 text-center mx-auto mb-5 mb-sm-9">
				<h2 className="h1">What we do?</h2>
				<p>We help companies across the world achieve their goals by building customized IT solutions.</p>
			</div>

			<div className="row">
				<div className="col-md-4 mb-7">
					<div className="text-center px-lg-3">
						<figure className="max-w-8rem mx-auto mb-4">
							<img className="img-fluid" src="/assets/theme/svg/icons/icon-45.svg" alt="Software Development"/>
						</figure>
						<h3>Software Development</h3>
						<p>Our focus is building custom software solutions tailored to each and every industry or customer's necessity.</p>
					</div>
				</div>

				<div className="col-md-4 mb-7">
					<div className="text-center px-lg-3">
						<figure className="max-w-8rem mx-auto mb-4">
							<img className="img-fluid" src="/assets/theme/svg/icons/icon-14.svg" alt="IT Consulting"/>
						</figure>
						<h3>IT Consulting</h3>
						<p>Whether you're a startup or a global enterprise, you can trust our top minds to help you navigate through any roadblocks and find the best approach.</p>
					</div>
				</div>

				<div className="col-md-4 mb-7">
					<div className="text-center px-lg-3">
						<figure className="max-w-8rem mx-auto mb-4">
							<img className="img-fluid" src="/assets/theme/svg/icons/icon-23.svg" alt="DevOps"/>
						</figure>
						<h3>Accelerate your business</h3>
						<p>Grow your business in a successful way through high-end reliable systems. Boost your business performance regardless of the industry.</p>
					</div>
				</div>
			</div>

			<img className="img-fluid d-none d-md-block w-75 mx-auto mb-7"
			     src="/assets/theme/svg/components/three-pointers.svg" alt="SVG Arrow"/>

			<div className="w-md-60 w-lg-50 text-center mx-auto mb-7">
				<p className="text-dark">Our process is iterative, flexible and focused on finding valuable solutions for our clients and their customers.</p>
			</div>

			<div className="position-relative w-lg-75 mx-lg-auto mb-4">
				<figure>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						x="0px" y="0px" viewBox="0 0 1109.8 797.1"
					>
						<path fill="#f9fbff"
						      d="M105.1,267.1C35.5,331.5-3.5,423,0.3,517.7C6.1,663,111,831.9,588.3,790.8c753-64.7,481.3-358.3,440.4-398.3c-4-3.9-7.9-7.9-11.7-12L761.9,104.8C639.4-27.6,432.5-35.6,299.9,87L105.1,267.1z"/>
					</svg>
				</figure>

				<div className="device device-browser">
					<img className="img-fluid" src="/assets/theme/svg/components/browser.svg"
					     alt="Image Description"/>
					<img className="device-browser-screen" src="/assets/screenshots/website-screenshot-2.png"
					     alt="Image Description"/>
				</div>
			</div>
		</div>
	);
};

export default WhatWeDoSection;
