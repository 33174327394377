import EmailModel from "./EmailModel";

export default class EmailService {
	static emailApiEndpoint = 'https://quxldknnva.execute-api.eu-central-1.amazonaws.com/production/email/send';

	static sendEmail(emailModel) {
		return fetch(this.emailApiEndpoint, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin', // include, *same-origin, omit
			body: emailModel.serialize()
		});
	}

}
