import React from 'react';
import FooterLinks from "../../../../../components/Layout/FooterLinks";

const Footer = () => {
  return (
    <footer className="position-absolute right-0 bottom-0 left-0">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center space-1">
          <p className="small text-muted mb-0">&copy; DEVACCENT {new Date().getFullYear()}</p>

          <FooterLinks />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
