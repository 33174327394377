import React, { useEffect, useRef, useState } from "react";
import EmailModel from "../../../../../../../modules/Contact/EmailModel";

const Form = ({onSendEmailCallback, isButtonLoading, isButtonDisabled}) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('');
	const [budget, setBudget] = useState('$5,000 to $10,000');
	const [description, setDescription] = useState('');
	const [platform, setPlatform] = useState({web: false, mobile: false, other: false});

	const webPlatformRef = useRef(null);
	const mobilePlatformRef = useRef(null);
	const otherPlatformRef = useRef(null);

	const refMaps = {
		web: webPlatformRef,
		mobile: mobilePlatformRef,
		other: otherPlatformRef,
	}


	const budgets = [
		'$5,000 to $10,000',
		'$10,000 to $20,000',
		'$20,000 to $50,000',
		'$100,000 to $200,000',
		'$200,000+'
	];
	const handleSubmit = (e) => {
		e.preventDefault();

		const emailModel = new EmailModel();
		emailModel.name = name;
		emailModel.emailAddress = email;
		emailModel.companyName = company;
		emailModel.budget = budget;
		emailModel.platform = platform;
		emailModel.description = description;

		onSendEmailCallback && onSendEmailCallback(emailModel);
	};

	useEffect(() => {
	}, []);

	const changePlatformHandler = (platformName) => () => {
		const isChecked = refMaps[platformName].current.checked;
		setPlatform({...platform, [platformName]: isChecked});
	}

  return (
	  <form className="js-validate row justify-content-lg-center" onSubmit={handleSubmit}>
		  <div className="col">
			  <div className="card-header bg-light text-center py-4 px-5 px-md-6">
				  <h2 className="h4 mb-0">
					  Contact Us
				  </h2>
			  </div>

			  <div className="card p-5">

				  <div className="js-form-message mb-4 mb-md-6">
					  <label className="input-label">Your name</label>
					  <input
						  type="text"
						  className="form-control"
						  name="firstName"
						  placeholder="John Doe"
						  aria-label="John Doe"
						  required data-msg="Please enter your frist name."
						  value={name}
						  onChange={(e) => setName(e.target.value)}
					  />
				  </div>

				  <div className="js-form-message mb-4 mb-md-6">
					  <label className="input-label">Your email address</label>
					  <input
						  type="email"
						  className="form-control"
						  name="emailAddress"
						  placeholder="john.doe@company.com"
						  aria-label="john.doe@company.com"
						  required
						  data-msg="Please enter a valid email address."
						  value={email}
						  onChange={(e) => setEmail(e.target.value)}
					  />
				  </div>

				  <div className="mb-4 mb-md-6">
					  <label className="input-label">
						  Your company name
						  <span className="text-muted font-weight-normal ml-1">(optional)</span>
					  </label>
					  <input
						  type="text"
						  className="form-control"
						  placeholder="Company Inc."
						  aria-label="Company Inc."
						  value={company}
						  onChange={(e) => setCompany(e.target.value)}
					  />
				  </div>

				  <div className="mb-4 mb-md-6">
					  <label className="input-label">
						  Choose your platform
						  <span className="text-muted font-weight-normal ml-1">(optional)</span>
					  </label>
					  <div className="btn-group btn-group-toggle btn-group-segment d-flex" data-toggle="buttons">
						  <label className="btn flex-fill" onClick={changePlatformHandler('web')}>
							  <input type="checkbox" name="Web" id="web" ref={webPlatformRef} /> Web
						  </label>
						  <label className="btn flex-fill" onClick={changePlatformHandler('mobile')}>
							  <input type="checkbox" name="Mobile App" id="mobile" ref={mobilePlatformRef} /> Mobile app
						  </label>
						  <label className="btn flex-fill" onClick={changePlatformHandler('other')}>
							  <input type="checkbox" name="Other" id="other" ref={otherPlatformRef} /> Other
						  </label>
					  </div>
				  </div>

				  <div className="js-form-message mb-4 mb-md-6">
					  <label className="input-label">Tell us about your budget</label>
					  <select
						  className="form-control custom-select"
						  required data-msg="Please select your budget."
						  onChange={(e) => setBudget(e.target.value)}
						  value={budget}
					  >
						  {budgets.map((item,index) =>
						    <option key={index} value={item}>{item}</option>
						  )}
					  </select>
				  </div>

				  <div className="js-form-message mb-4 mb-md-6">
					  <label className="input-label">Tell us about your project</label>
					  <textarea
						  className="form-control"
						  rows="4"
						  name="description"
						  placeholder="Hi there, I would like to ..."
						  aria-label="Hi there, I would like to ..."
						  required data-msg="Please enter a short project description."
						  onChange={(e) => setDescription(e.target.value)}
						  value={description}
					  />
				  </div>

				  <div className="text-center">
					  <div className="mb-2">
						  <button disabled={isButtonDisabled} type="submit" className="btn btn-primary">
							  {isButtonLoading ? 'Sending...' : 'Let\'s Start Working Together'}
						  </button>
					  </div>
				  </div>
			  </div>
		  </div>
	  </form>
  );
};

export default Form;
