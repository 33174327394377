import React from 'react';
import Logo from "../../../../../components/Branding/Logo";
import FooterLinks from "../../../../../components/Layout/FooterLinks";

const Footer = () => {
  return (
    <footer className="container space-2">
      <div className="row align-items-md-center">
        <div className="col-lg-3 col-md-6 col-sm-6 col-6 mb-4 mb-md-0">
          <Logo />
        </div>

        <div className="col-lg-9 col-md-6 col-sm-6 col-6 text-right">
          <FooterLinks />

        </div>
      </div>
    </footer>
  );
};

export default Footer;
