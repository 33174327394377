import React, {useEffect} from 'react';

const FourOhFourScreen = () => {

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];

    const className = ' bg-image-hero-fixed';

    body.className += className;
    body.style.backgroundImage = 'url(/assets/theme/svg/illustrations/error-404.svg)';

    return () => {
      body.className = body.className.replace(className, '');
      body.style.backgroundImage = 'none';
    }
  }, []);

  return (
    <main id="content" role="main">
      <div className="d-lg-flex">
        <div className="container d-lg-flex align-items-lg-center min-vh-lg-100 space-4">
          <div className="w-lg-60 w-xl-50">

            <div className="mb-4">
              <img className="max-w-23rem mb-3" src="/assets/theme/svg/illustrations/error-number-404.svg" alt="SVG Illustration" />
                <p className="lead">
                  Oops! Looks like you followed a bad link.
                  <br />
                </p>
            </div>

            <a className="btn btn-wide btn-primary transition-3d-hover" href="/">Back Home</a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FourOhFourScreen;
