import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";

const LandingPageLayout = ({component: Component}) => {
  return (
    <React.Fragment>
      <Header />
      <Component />
      <Footer />
    </React.Fragment>
  );
};

export default LandingPageLayout;
