import React from "react";

const ServiceDescription = ({description}) => {
	return (
		<div className="position-relative">
			<div className="card z-index-2">
				<div className="card-body">
					<span>{description}</span>
				</div>
			</div>

			<figure className="max-w-19rem w-100 position-absolute bottom-0 right-0">
				<div className="mb-n7 mr-n7">
					<img className="img-fluid" src="/assets/theme/svg/components/dots-2.svg"/>
				</div>
			</figure>
		</div>
	)
}

export default ServiceDescription;
