import React from "react";

const ServiceItem = ({index, title, description, image, dataToggleHref, isActive}) => {
	return (
		<li className="nav-item w-100 mx-0">
			<a
				className={`nav-link p-4 ${isActive === true ? 'active' : ''}`}
				id={`service-${index}-tab`}
				data-toggle="pill"
				href={`#service-${index}`}
				role="tab"
				aria-controls={`service-${index}`}
				aria-selected="false"
			>
				<div className="media align-items-center align-items-lg-start">
					<figure className="w-100 max-w-6rem mt-2 mr-4">
						<img className="img-fluid" src={image.path} alt={image.alt} />
					</figure>
					<div className="media-body">
						<h4 className="mb-0">{title}</h4>
						<div className="d-none d-lg-block mt-2">
							<p className="text-body mb-0">{description}</p>
						</div>
					</div>
				</div>
			</a>
		</li>
	);
}

export default ServiceItem;
