import React from 'react';

import HeroSection from "./components/Sections/HeroSection";
import WhatWeDoSection from "./components/Sections/WhatWeDoSection";
import CompanyValuesSection from "./components/Sections/CompanyValuesSection";
import ContactForm from "./components/Sections/ContactForm";
import ServicesSection from "./components/Sections/ServicesSection";
import TechnologiesSection from "./components/Sections/TechnologiesSection";
import { ContactProvider } from "../../modules/Contact/ContactProvider";

const HomeScreen = () => {
  return (
    <React.Fragment>
      <HeroSection />
      <WhatWeDoSection />
      <ServicesSection />
      <TechnologiesSection />
      <CompanyValuesSection />
      <ContactProvider>
	      <ContactForm />
      </ContactProvider>
    </React.Fragment>
  );
};

export default HomeScreen;
