import React, {createContext, useState} from 'react';
import { STATE_INITIAL } from "./ContactConstants";

const initialState = {
  state: STATE_INITIAL,
	setState: () => {},
};

export const ContactContext = createContext(initialState);
export const ContactProvider = ({children}) => {
  const [state, setState] = useState(initialState.state);

  return (
    <ContactContext.Provider
      value={{
        state,
	      setState,
      }}>
      {children}
    </ContactContext.Provider>
  );
};
