import FrontendDevelopment from "./components/ServiceItemContent/FrontendDevelopment";
import DevOpsServicesContent from "./components/ServiceItemContent/DevOpsServicesContent";
import MobileAppDevelopmentServicesContent
	from "./components/ServiceItemContent/MobileAppDevelopmentServicesContent";
import BackendDevelopmentServicesContent
	from "./components/ServiceItemContent/BackendDevelopmentServicesContent";

export const services = [
	{
		title: 'Front-end Development',
		description: 'Your business embodied in the perfect UI!',
		image: {
			path: '/assets/theme/svg/icons/icon-44.svg',
			alt: ''
		},
		content: FrontendDevelopment,
	},

	{
		title: 'Back-end Development',
		description: 'We define the perfect core for your business.',
		image: {
			path: '/assets/theme/svg/icons/icon-3.svg',
			alt: ''
		},
		content: BackendDevelopmentServicesContent,
	},

	{
		title: 'Mobile App Development',
		description: 'We help you connect your ideas to the latest mobile technologies.',
		image: {
			path: '/assets/theme/svg/icons/icon-9.svg',
			alt: ''
		},
		content: MobileAppDevelopmentServicesContent,
	},
	{
		title: 'Infrastructure & DevOps',
		description: 'Automate and integrate the processes between the software and your teams.',
		image: {
			path: '/assets/theme/svg/icons/icon-23.svg',
			alt: ''
		},
		content: DevOpsServicesContent,
	},
];
