import React, { useContext } from "react";
import Form from "./components/Form";
import { ContactContext } from "../../../../../modules/Contact/ContactProvider";
import {
	STATE_ERROR, STATE_INITIAL,
	STATE_SENDING,
	STATE_SENT,
} from "../../../../../modules/Contact/ContactConstants";
import EmailService from "../../../../../modules/Contact/EmailService";
import FormMessage from "./components/FormMessage";

const ContactForm = () => {
	const {state, setState} = useContext(ContactContext);

	const sendEmailHandler = (emailData) => {
		setState(STATE_SENDING);
		EmailService.sendEmail(emailData)
			.then(() => setState(STATE_SENT))
			.catch(() => setState(STATE_ERROR));
	};

  return (
	  <div className="container space-2">
		  <a name="contact" id={'contact'} />

		  <div className="row justify-content-center mb-5">
			  <div className="col-md-1 d-none d-md-inline-block">

				  <figure className="position-absolute bottom-0 left-0 w-100">
					  <img className="img-fluid" src="/assets/theme/svg/illustrations/flowers.svg"
					       alt="Image Description"/>
				  </figure>

			  </div>

			  <div className="col-md-10 col-lg-7 mt-n11">
				  {(state === STATE_SENDING || state === STATE_INITIAL) && (
					  <Form
						  onSendEmailCallback={sendEmailHandler}
						  isButtonLoading={state === STATE_SENDING}
						  isButtonDisabled={state === STATE_SENDING}
					  />
				  )}

				  {(state === STATE_SENT || state === STATE_ERROR) && <FormMessage isError={state === STATE_ERROR}/>}
			  </div>

			  <div className="col-md-1 d-none d-md-inline-block">

				  <figure className="position-absolute bottom-0 left-0 w-100">
					  <img className="img-fluid" src="/assets/theme/svg/illustrations/chatting-man.svg"
					       alt="Image Description"/>
				  </figure>

			  </div>
		  </div>

		  <div className="text-center mt-5">
			  <p className="small">We'll get back to you in 1-2 business days.</p>
		  </div>
	  </div>
  );
};

export default ContactForm;
