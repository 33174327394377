import React from "react";
import FeatureItem from "../components/FeatureItem";

const MobileAppDevelopmentServicesContent = () => {

	const features = [
		'iOS',
		'Android',
		'Hybrid Applications',
		'Push Notifications',
		'App Store Listing'
	];

	return (
		<React.Fragment>
		<div className="card container p-5 p-lg-2 space-lg-2">
			<div className="row align-items-lg-center">
				<div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
					<div className="mr-lg-5">
						<div className="mb-5">
							<p>We provide high performing, innovative and scalable custom mobile solutions for any kind of mobile device.</p>
						</div>

						<div className={'align-items-lg-center'}>
							{features.map(
								(item, index) =>
									<FeatureItem key={index} text={item} />
							)}
						</div>

					</div>
				</div>

				<div className="col-lg-7 order-lg-1">
					<div className="position-relative max-w-50rem mx-auto mr-n4">

						<div className="device device-iphone-x w-75 mx-auto z-index-2">
							<img className="device-iphone-x-frame" src="/assets/theme/svg/components/iphone-x.svg"
							     alt="Image Description" />
								<img className="device-iphone-x-screen" src="/assets/theme/img/407x867/img9.jpg"
								     alt="Image Description" />
						</div>

						<div
							className="position-absolute bottom-0 right-0 max-w-50rem w-100 z-index-0 mx-auto">
							<img className="img-fluid" src="/assets/theme/svg/components/abstract-shapes-3.svg"
							     alt="Image Description" />
						</div>

					</div>
				</div>
			</div>
		</div>
		</React.Fragment>
	)

};

export default MobileAppDevelopmentServicesContent;
