import React from "react";
import TechnologyItem from "./TechnologyItem";
import LogosList from "./LogosList";
import {technologies} from "./technologies";

const TechnologiesSection = () => {
	return (
		<div className='container space-2'>
			<a name="technologies" id={'technologies'} />
			<div className="w-lg-65 text-center mx-auto mt-10 mb-5 mb-sm-9">
				<h2 className="h1">Tech Stack</h2>
				<p>Cutting-edge and industry-standard technologies we use to empower your business.</p>
			</div>

			<div className='card container space-1'>
				{technologies.map(
					(item, index) =>
						<TechnologyItem key={index} category={item.name}>
							<LogosList logos={item.logos} />
						</TechnologyItem>
				)}
			</div>
		</div>

	);

};

export default TechnologiesSection;
