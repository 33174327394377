import React from "react";
import {Switch, BrowserRouter} from "react-router-dom";

//Route Types
import ContextAwareRoute from "./RouteTypes/ContextAwareRoute";

//Layouts
import LandingPageLayout from "./Layouts/LandingPageLayout";
import ErrorPageLayout from "./Layouts/ErrorPageLayout";

//Screens
import HomeScreen from "../../screens/Home";
import FourOhFourScreen from "../../screens/Errors/404";

const ApplicationRouter = () => {
	return (
		<BrowserRouter>
			<Switch>
				<ContextAwareRoute exact path='/' layout={LandingPageLayout} component={HomeScreen}/>

				<ContextAwareRoute exact path='*' layout={ErrorPageLayout} component={FourOhFourScreen}/>
			</Switch>
		</BrowserRouter>
	);
};

export default ApplicationRouter;
