export default class EmailModel {
	name = undefined;
	emailAddress = undefined;
	companyName = undefined;

	platform = undefined;
	budget = undefined;

	description = undefined;

	/*
 * @returns {string}
 */
	serialize() {
		return JSON.stringify(this);
	}
}
