import React from "react";

const FeatureItem = ({text}) => (
	<div className="media mb-4">
      <span className="icon icon-xs icon-soft-primary icon-circle mr-3">
        <i className="fas fa-check"></i>
      </span>
		<div className="media-body text-dark">
			{text}
		</div>
	</div>
);

export default FeatureItem;
