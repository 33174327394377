import React from "react";
import FeatureItem from "../components/FeatureItem";
import ServiceDescription from "../components/ServiceDescription";

const FrontendDevelopment = () => {

	const features = [
		'Web applications',
		'Single-page applications',
		'Offline-first applications',
		'PWA integration',
	];

	return (
		<div className="card overflow-hidden">
			<div className="container p-5 space-lg-2">
				<div className="mx-md-auto">
					<div className="row justify-content-center align-items-md-center">
						<div className="col-md-5 mb-7 mb-md-0">
							{features.map(
								(item, index) =>
									<FeatureItem key={index} text={item} />
							)}
						</div>

						<div className="col-md-7 col-lg-5">
							<ServiceDescription
								description={'You can count on our web development team and our vast experience in making user-friendly web interfaces that will improve the user experience and customer engagement.'}
						  />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FrontendDevelopment
