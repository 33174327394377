import React from "react";

const ServiceItemContentWrapper = ({children: Component, index, isActive}) => {

	return (
		<div
			className={`tab-pane fade show ${isActive === true ? 'active' : ''}`}
			id={`service-${index}`}
			role="tabpanel"
			aria-labelledby={`service-${index}-tab`}
		>
			<Component />
		</div>
	);

};

export default ServiceItemContentWrapper;
